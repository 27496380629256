import React from 'react'

export default class FourOhFour extends React.Component {
  constructor() {
    super({})
  }

  render() {
    return (
      <h1>Not found</h1>
    )
  }
}